import { createApp, h, reactive } from 'vue';
import AppWrapper from './AppWrapper.vue';
import router from './router';

import { useMQTT } from 'mqtt-vue-hook';

//import VueJwtDecode from 'vue-jwt-decode';
import PrimeVue from 'primevue/config';


import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from "primevue/badgedirective";
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import CascadeSelect from 'primevue/cascadeselect';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import DynamicDialog  from 'primevue/dynamicdialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import FullCalendar from 'primevue/fullcalendar';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner   from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import StyleClass from 'primevue/styleclass';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Editor from 'primevue/editor';

import CodeHighlight from './AppCodeHighlight';
import BlockViewer from './BlockViewer';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import DialogService from 'primevue/dialogservice';

import { VueReCaptcha } from 'vue-recaptcha-v3';
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";

const app = createApp({
    render() { return h(AppWrapper); }
});
//app.use(VueJwtDecode);
app.use(DialogService);
app.use(VueReCaptcha, { siteKey: '6LckE7AmAAAAAEiD38ObVxWEk2uu4wGO0Qlp5cNM' });
app.use(Vue3SimpleHtml2pdf);

app.use(PrimeVue, {
    ripple: true,
    locale: {
        "startsWith": "Inizia con",
        "contains": "Contiene",
        "notContains": "Non contiene",
        "endsWith": "Finisce con",
        "equals": "Equivale",
        "notEquals": "Non uguale",
        "noFilter": "Senza Filtro",
        "filter": "Filtro",
        "lt": "Minore di",
        "lte": "Minore o uguale a",
        "gt": "Maggiore di",
        "gte": "Maggiore o uguale a",
        "dateIs": "La data è",
        "dateIsNot": "La data non è",
        "dateBefore": "La data è precedente",
        "dateAfter": "La data è successiva",
        "custom": "Personalizzato",
        "clear": "Cancella tutto",
        "apply": "Applica",
        "matchAll": "Abbina tutto",
        "matchAny": "Abbina alcuni",
        "addRule": "Aggiungi regola",
        "removeRule": "Rimuovi regola",
        "accept": "Si",
        "reject": "No",
        "choose": "Scegli",
        "upload": "Carica",
        "cancel": "Annulla",
        "completed": "Completato",
        "pending": "In corso",
        "dayNames": [
            "Domenica",
            "Lunedi",
            "Martedì",
            "Mercoledì",
            "Giovedì",
            "Venerdì",
            "Sabato"
        ],
        "dayNamesShort": [
            "Dom",
            "Lun",
            "Mar",
            "Mer",
            "Gio",
            "Ven",
            "Sab"
        ],
        "dayNamesMin": [
            "Do",
            "Lu",
            "Ma",
            "Me",
            "Gi",
            "Ve",
            "Sa"
        ],
        "monthNames": [
            "Gennaio",
            "Febbraio",
            "Marzo",
            "Aprile",
            "Maggio",
            "Giugno",
            "Luglio",
            "Agosto",
            "Settembre",
            "Ottobre",
            "Novembre",
            "Dicembre"
        ],
        "monthNamesShort": [
            "Gen",
            "Feb",
            "Mar",
            "Apr",
            "Mag",
            "Giu",
            "Lug",
            "Ago",
            "Set",
            "Ott",
            "Nov",
            "Dic"
        ],
        "chooseYear": "Seleziona Anno",
        "chooseMonth": "Seleziona Mese",
        "chooseDate": "Seleziona Data",
        "prevDecade": "Decade precedente",
        "nextDecade": "Decade successiva",
        "prevYear": "Anno precedente",
        "nextYear": "Anno successivo",
        "prevMonth": "Mese precedente",
        "nextMonth": "Mese successivo",
        "prevHour": "Ora precedente",
        "nextHour": "Ora successiva",
        "prevMinute": "Minuto precedente",
        "nextMinute": "Minuto successivo",
        "prevSecond": "Secondo precedente",
        "nextSecond": "Secondo successivo",
        "am": "AM",
        "pm": "PM",
        "today": "Oggi",
        "weekHeader": "Sett",
        "firstDayOfWeek": 1,
        "dateFormat": "dd/mm/yy",
        "weak": "Debole",
        "medium": "Medio",
        "strong": "Forte",
        "passwordPrompt": "Inserisci la password",
        "emptyFilterMessage": "Nessuna opzione disponibile",
        "searchMessage": "{0} risultati disponibili",
        "selectionMessage": "{0} elementi selezionati",
        "emptySelectionMessage": "Nessun elemento selezionato",
        "emptySearchMessage": "Nessun risultato trovato",
        "emptyMessage": "Nessun risultato trovato",
        "aria": {
            "trueLabel": "Vero",
            "falseLabel": "Falso",
            "nullLabel": "Non selezionato",
            "firstPageLabel": "Prima pagina",
            "lastPageLabel": "Ultima pagina",
            "nextPageLabel": "Pagina successiva",
            "previousPageLabel": "Pagina precedente",
            "selectLabel": "Seleziona",
            "unselectLabel": "Deseleziona",
            "expandLabel": "Espandi",
            "collapseLabel": "Riduci",
            "star": "1 stella",
            "stars": "{star} stelle",
            "selectAll": "Seleziona tutti gli elementi",
            "unselectAll": "Deseleziona tutti gli elementi",
            "close": "Chiudi",
            "previous": "Precedente",
            "next": "Successivo",
            "navigation": "Naviga",
            "scrollTop": "Torna sù",
            "moveTop": "Vai in cima",
            "moveUp": "Vai sopra",
            "moveDown": "Vai sotto",
            "moveBottom": "Vai in fondo",
            "moveToTarget": "Vai all'elemento",
            "moveToSource": "Vai alla sorgente",
            "moveAllToTarget": "Muovi tutto all'elemento",
            "moveAllToSource": "Muovi tutto alla sorgente",
            "pageLabel": "{page}",
            "rowsPerPageLabel": "Elementi per pagina",
            "jumpToPageDropdownLabel": "Vai alla Dropdown delle pagine",
            "jumpToPageInputLabel": "Vai all'Input delle pagine",
            "selectRow": "Seleziona riga",
            "unselectRow": "Deseleziona riga",
            "expandRow": "Espandi riga",
            "collapseRow": "Riduci riga",
            "showFilterMenu": "Mostra Menu filtri",
            "hideFilterMenu": "Nascondi Menu filtri",
            "filterOperator": "Operatore di filtro",
            "filterConstraint": "Costante di filtro",
            "editRow": "Modifica riga",
            "saveEdit": "Salva modifica",
            "cancelEdit": "Annulla modifica",
            "listView": "Lista",
            "gridView": "Griglia",
            "slide": "Scorri",
            "slideNumber": "{slideNumber}",
            "zoomImage": "Zoom Immagine",
            "zoomIn": "Ingrandisci",
            "zoomOut": "Riduci",
            "rotateRight": "Ruota a destra",
            "rotateLeft": "Ruota a sinistra"
        }
    }
});
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);

app.config.globalProperties.$appState = reactive({ colorScheme: 'light' });

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.directive('styleclass', StyleClass);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('CascadeSelect', CascadeSelect);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('Row', Row);
app.component('ColumnGroup', ColumnGroup);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('DynamicDialog', DynamicDialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('FullCalendar', FullCalendar);
app.component('Galleria', Galleria);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('Editor', Editor);

app.component('BlockViewer', BlockViewer);

//app.provide('mqttHook', useMQTT());
app.config.globalProperties.$mqttHook = useMQTT();
app.mount('#app');
