import axios from '@/utilities/Axios.js'

export default async function registerServiceWorker() {
    const urlBase64ToUint8Array = (base64String) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        try {
            let registration = await navigator.serviceWorker.register('/service-worker.js')
            let subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array('BOvmI2mneFEvFDbNj8X_a_fDcoQ8S-1TyQdsv7tf4XtV5_gd5nzGsWw-xBOD85gNGOCCmeMUHEHs-DJfF7noHa0')
            });
            let response = await axios.post('/webpush', {
                action: 'subscribe',
                subscription: JSON.parse(JSON.stringify(subscription))
            });
            console.log('Subscription inviata con successo:', response.data);
            return registration
        } catch (error) {
            console.error('Errore durante la registrazione o l\'invio della subscription:', error);
            throw new Error('Errore durante la registrazione o l\'invio della subscription');
        }
    } else {
        console.error('Service workers are not supported in this browser', navigator.serviceWorker, window.PushManager);
        throw new Error('Service workers are not supported in this browser');
    }
}