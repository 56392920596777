<template>
	<div class="layout-footer mt-4">
        <div class="footer-logo-container">
            <img id="footer-logo" :src="'layout/images/logo-'+ (colorScheme === 'light' ? 'dark' : 'light') + '.png'" alt="atlantis-layout"/>
            <span class="app-name">DS Business Intelligence | {{token?.nome}} {{token?.cognome}}</span>
        </div>
        <span class="copyright">&#169; Digital System S.r.l. - {{year}}</span>
    </div>
</template>

<script>
import VueJwtDecode from 'vue-jwt-decode'
export default {
	props: {
		colorScheme: String,
        VueJwtDecode
	},
    computed: {
        token() {
            return VueJwtDecode.decode(localStorage.getItem('token'))
        },
        year() {
            return new Date().getFullYear()
        }
    }
}
</script>

