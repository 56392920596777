<template>
    <div class="layout-topbar">
        <div class="layout-topbar-left">
            <a href="#" class="topbar-menu-button" @click="onMenuButtonClick($event)">
                <i class="pi pi-bars"></i>
            </a>

            <!-- <a href="#" class="logo">
                <img :src="'layout/images/logo-'+ (colorScheme === 'light' ? 'dark' : 'light') + '.png'">
            </a> -->

            <a @click="redirectRuolo">
                <img :src="'layout/images/appname-' + (colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="app-name" />
            </a>
        </div>

        <AppMenu
            :model="items"
            :menuMode="menuMode"
            :colorScheme="colorScheme"
            :menuActive="menuActive"
            :activeInlineProfile="activeInlineProfile"
            @sidebar-mouse-over="onSidebarMouseOver"
            @sidebar-mouse-leave="onSidebarMouseLeave"
            @toggle-menu="onToggleMenu"
            @change-inlinemenu="onChangeActiveInlineMenu"
            @menu-click="onMenuClick"
            @root-menuitem-click="onRootMenuItemClick"
            @menuitem-click="onMenuItemClick"
        />

        <div class="layout-topbar-right">
            <ul class="layout-topbar-right-items">
                <li id="profile" class="profile-item" :class="{ 'active-topmenuitem': topbarMenuActive }">
                    <a href="#" @click="onTopbarItemClick($event, 'profile')">
                        <img src="/layout/images/logo-light.png" />
                    </a>

                    <ul class="fadeInDown">
                        <li role="menuitem">
                            <a href="#" @click="logout">
                                <i class="pi pi-fw pi-sign-out"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#" @click="onTopbarItemClick($event, 'chat')"> <i class="topbar-icon pi pi-fw pi-whatsapp whatsapp-icon"></i> <span class="topbar-item-name">Chat</span> </a>
                </li>
                <li id="notifications" class="notifications-item" :class="{ 'active-topmenuitem': notificationMenuActive }">
                    <a href="#" @click="onTopbarItemClick($event, 'notifications')">
                        <i class="topbar-icon pi pi-fw pi-bell" :class="{ 'text-blue-400': notificationMenuActive }"></i>
                        <span class="topbar-badge" v-show="notificationsToReadCount > 0">{{ notificationsToReadCount }}</span>
                        <span class="topbar-item-name">Notifications</span>
                    </a>
                    <ul class="fadeInDown" style="width: 300px !important">
                        <li>
                            <h6 class="m-0 p-2">Notifiche</h6>
                        </li>
                        <li>
                            <Button @click="segnaComeLette">
                                <i class="pi pi-fw pi-check"></i>
                                <span>Segna come lette</span>
                            </Button>
                        </li>
                        <div class="notifications-scroll-area" @scroll="onNotificationsScroll">
                            <li role="menuitem" v-for="notification in visibleNotifications" :key="notification.id" class="notification-item">                            <a href="#" @click="onTopbarSubItemClick($event, { type: 'notification', item: notification })" :class="{ 'read-notification': notification.letto, 'unread-notification': !notification.letto }">
                                <h6 class="m-0 p-0">{{ notification.titolo }}</h6>
                                <span>{{ notification.messaggio }}</span>
                                <small class="block text-right">{{ formatDate(notification.data_creazione) }}</small>
                                </a>
                            </li>
                            <li>
                                {{loading}}
                                <Button @click="checkNotifications(20)" :loading="loading">
                                    <span>Carica altre 10</span>
                                </Button>
                            </li>
                         </div>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import AppMenu from './AppMenu.vue'
import axios from '@/utilities/Axios.js'
import VueJwtDecode from 'vue-jwt-decode'
import formatUtils from '@/utilities/format.js'
import registerServiceWorker from '@/service/PushNotification.js'
export default {
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click', 'menubutton-click', 'sidebar-mouse-over', 'sidebar-mouse-leave', 'toggle-menu', 'change-inlinemenu', 'topbar-item-click'],
    data() {
        return {
            activeTopbarItem: null,
            notificationMenuActive: false,
            notifications: [],
            notificationsToReadCount: 0,
            notificationModalOpen: false,
            registerServiceWorker,
            serviceWorkerRegistration: null,
            notificationsPerPage: 10,
            loading: false,
      currentPage: 1,
        }
    },
    props: {
        items: Array,
        menuMode: String,
        colorScheme: String,
        // add a background color to the topbar

        topbarMenuActive: {
            type: Boolean,
            default: false,
        },
        menuActive: {
            type: Boolean,
            default: false,
        },
        activeInlineProfile: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        token() {
            const token = localStorage.getItem('token')
            if(token) return VueJwtDecode.decode(localStorage.getItem('token'))
            return null
        },
        nickname() {
            return this.token?.nickname
        },
        visibleNotifications() {
      return this.notifications.slice(0, this.notificationsPerPage * this.currentPage);
    },
    },
    mounted() {
        console.log('AppTopbar mounted')
        this.checkNotifications()
        this.subscribeMqtt()
    },
    methods: {
        async getNotificationCount() {
            try {
                const response = await axios.get('/notifications/count')
                if (response.data?.length == 0) throw new Error('Errore nel conteggio delle notifiche')
                this.notificationsToReadCount = response.data[0].count
            } catch (error) {
                console.log(error)
            }
        },
        async checkNotifications(limit = 10) {
            this.loading = true
            console.log('check notifications')
            if (!this.token) return
            try {
                this.notifications = (await axios.get('/notifications', {params: {limit}})).data
                await this.getNotificationCount()
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        async segnaComeLette() {
            try {
                await axios.put('/notifications')
                await this.checkNotifications()
            } catch (error) {
                console.log(error)
            }
        },
        subscribeMqtt() {
            if (!this.nickname) return
            this.$mqttHook.registerEvent(`${this.nickname}/notifications`, async (_, message) => {
                await this.checkNotifications()
                console.log('Notifica ricevuta:', message)
                this.$toast.add({
                    severity: 'info',
                    summary: 'Notifica',
                    detail: message,
                    life: 5000,
                })
            })
        },
        onMenuClick(event) {
            this.$emit('menu-click', event)
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event)
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event)
        },
        async onMenuButtonClick(event) {
            this.$emit('menubutton-click', event)
            try {
                this.serviceWorkerRegistration = await this.registerServiceWorker()
            } catch (error) {
                console.log(error)
                this.$toast.add({
                    severity: 'error',
                    summary: 'Errore',
                    detail: error.message,
                    life: 5000,
                })
            }
        },
        onTopbarItemClick(event, item) {
            if (item == 'notifications') {
                this.notificationMenuActive = !this.notificationMenuActive
                this.notificationModalOpen = this.notificationMenuActive
                event.preventDefault()
                return
            } else if (item == 'chat') {
                this.$router.push('/chat')
                event.preventDefault()
                return
            }
            this.$emit('topbar-item-click', event, item)
            event.preventDefault()
        },
        onTopbarSubItemClick(event, payload) {
            const { type, item } = payload
            if (type == 'notification') {
                this.notificationMenuActive = false
                axios
                    .put(`/notifications/${item.id}`)
                    .then(async () => {
                        await this.checkNotifications()
                    })
                    .catch(error => {
                        console.log(error)
                    })
                if (item.link) {
                    this.$router.push(item.link)
                }
            }
            event.preventDefault()
        },
        onSidebarMouseOver() {
            this.$emit('sidebar-mouse-over')
        },
        onSidebarMouseLeave() {
            this.$emit('sidebar-mouse-leave')
        },
        onToggleMenu(event) {
            this.$emit('toggle-menu', event)
        },
        onChangeActiveInlineMenu() {
            this.$emit('change-inlinemenu')
        },
        isOverlay() {
            return this.menuMode === 'overlay'
        },
        async logout() {
            localStorage.removeItem('token')
            await axios.post('/webpush', {
                action: 'unsubscribe',
                subscription: JSON.parse(JSON.stringify(this.serviceWorkerRegistration))
            });
            this.$router.replace({ path: '/login' })
        },
        redirectRuolo() {
            const ruolo = VueJwtDecode.decode(localStorage.getItem('token')).ruolo
            switch (ruolo) {
                case 'Sviluppatore':
                    this.$router.push('/direzione')
                    break
                case 'Direzione':
                    this.$router.push('/direzione')
                    break
                case 'Direzione Commerciale':
                    this.$router.push('/direzione')
                    break
                case 'Amministrazione':
                    this.$router.push('/')
                    break
                case 'Ufficio Acquisti':
                    this.$router.push('/direzione')
                    break
                case 'Tecnico':
                    this.$router.push('/')
                    break
                default:
                    this.$router.push('/')
                    break
            }
        },
        formatDate(date) {
            return formatUtils.formatDateTimeSql(date)
        },
        onNotificationsScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        this.loadMoreNotifications();
      }
    },
    loadMoreNotifications() {
      if (this.visibleNotifications.length < this.notifications.length) {
        this.currentPage++;
      }
    },
    },
    components: {
        AppMenu,
    },
}
</script>
<style scoped>
.read-notification {
    color: rgb(160, 160, 160) !important;
}
.notification-item {
    background-color: #ffffff;
    border: solid 1px #ccc;
}

.notification-item a {
    display: block;
    padding: 10px;
}

.notification-item a.read-notification {
    background-color: #ffffff;
    font-weight: normal;
}

.notification-item a.unread-notification {
    background-color: #f5f5f5;
    font-weight: bold;
}
.whatsapp-icon {
    color: #25d366; /* green */
}
notifications-container {
  width: 300px !important;
  max-height: 400px;
  display: flex;
  flex-direction: column;
}

.notifications-scroll-area {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 300px; /* Adjust as needed */
}

</style>
