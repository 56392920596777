const routePermissionsMap = {
    '/budgetcashflow': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti'],
    '/flussibancari': ['Sviluppatore', 'Amministrazione', 'Direzione'],
    '/autorizzafattureacquisti': ['Sviluppatore', 'Direzione'],
    '/autorizzaordiniacquisti': ['Sviluppatore', 'Direzione'],
    '/statoordinifornitoriconsegne': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti'],
    '/ordiniaperticlientiperdocumentocashflow': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti'],
    '/scadenzeapertefornitore': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti'],
    '/centrodicosto': ['Sviluppatore', 'Direzione', 'Amministrazione'],
    '/calcoloiva': ['Direzione', 'Sviluppatore', 'Amministrazione'],
    '/statoclienti': ['Sviluppatore', 'Direzione', 'Amministrazione'],
    '/gestionepermessi': ['Sviluppatore'],
    '/dialogcommenti': ['Sviluppatore'],
    '/incassi/:Cd_CF': ['Direzione', 'Sviluppatore', 'Amministrazione'],
    '/clienti': ['Sviluppatore', 'Direzione', 'Amministrazione'],
    '/fornitori': ['Sviluppatore', 'Direzione', 'Amministrazione'],
    '/inserimentomissioni': ['Sviluppatore', 'Direzione', 'Amministrazione'],
    '/parcoauto': ['Sviluppatore', 'Direzione', 'Amministrazione'],
    '/schedetratte': ['Sviluppatore'],
    '/richiestapermessieferie': ['Sviluppatore', 'Direzione', 'Amministrazione'],
    '/approvezionepermessieferie': ['Sviluppatore', 'Direzione', 'Amministrazione'],
    '/checkmatricole': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti', 'Magazzino Martina'],
    '/articolids': ['Sviluppatore'],
    '/richiestediarticolidadsweb': ['Sviluppatore', 'Amministrazione', 'Ufficio Acquisti', 'Magazzino Martina'],
    '/consegne': ['Sviluppatore', 'Direzione', 'Corriere Brindisi Taranto', 'Corriere Lecce', 'Corriere Bari Foggia'],
    '/consegnealbancomartinafranca': ['Sviluppatore', 'Direzione'],

    '/corrieri': ['Sviluppatore'],
    '/anagraficheclienti': ['Sviluppatore'],
    '/budgetagentimese': ['Sviluppatore'],
    '/budgetagentitrimestre': ['Sviluppatore'],
    '/budgetsquadramese': ['Sviluppatore'],
    '/budgetsquadratrimestre': ['Sviluppatore'],
    '/budgetstatotrimestre': ['Sviluppatore'],
    '/budgetstatomensile': ['Sviluppatore'],
    '/ordinievadibilimf': ['Sviluppatore', 'Direzione', 'Amministrazione'],
    '/lavoratodelgiornomf': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Magazzino Martina'],
    '/ordinievadibilimfGestore': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti', 'Magazzino Martina'],
    '/listeapertemartina': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti', 'Magazzino Martina'],
    '/listeapertecasarano': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti', 'Banco Casarano', 'Magazzino Casarano'],
    '/ordiniinevasicasarano': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti', 'Banco Casarano', 'Magazzino Casarano'],
    '/ordiniinevasilecce': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti', 'Banco Lecce', 'Magazzino Lecce'],
    '/listeapertelecce': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti', 'Banco Lecce', 'Magazzino Lecce'],
    '/ordiniinevasimodugno': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti', 'Banco Bari', 'Magazzino Bari'],
    '/listeapertemodugno': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti', 'Banco Bari', 'Magazzino Bari'],
    '/ordiniinevasioria': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti', 'Banco Oria', 'Magazzino Oria'],
    '/listeaperteoria': ['Sviluppatore', 'Direzione', 'Amministrazione', 'Ufficio Acquisti', 'Banco Oria', 'Magazzino Oria'],
    '/valorizzazionimagazzino': ['Sviluppatore','Direzione', 'Amministrazione', 'Ufficio Acquisti'],
    '/vendutodelgiorno': ['Sviluppatore'],
    '/vendutopercliente': ['Sviluppatore'],
    '/vendutoperbrand': ['Sviluppatore'],
    '/confrontofatturati': ['Sviluppatore'],
    '/sospesiclienti': ['Sviluppatore'],
    '/sospesiclientidaricontattare': ['Sviluppatore'],
    '/statoassicurazioneclienti': ['Sviluppatore', 'Amministrazione', 'Direzione', 'Direzione Commerciale'],
    '/acquisti': ['Sviluppatore', 'Ufficio Acquisti'],
    '/inevasofornitore': ['Sviluppatore'],
    '/riassortimentoscorte': ['Sviluppatore'],
    '/vendutoprodottiannocorrente': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale', 'Amministrazione'],
    '/top50clientiperbrand': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale', 'Amministrazione'],
    '/vendutoagentimensile': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale', 'Amministrazione'],
    '/vendutoconincidenza': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale', 'Amministrazione'],
    '/storicovendutofatturatoagenti': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale', 'Amministrazione'],
    '/fatturatoperclienti': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale', 'Agente', 'Banco Bari', 'Banco Lecce', 'Banco Oria', 'Banco Casarano', 'Amministrazione'],
    '/fatturatomensile': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale', 'Agente', 'Banco Bari', 'Banco Lecce', 'Banco Oria', 'Banco Casarano', 'Amministrazione'],
    '/fatturatomensilemesiscorsi': ['Sviluppatore'],
    '/fatturatopivot': ['Sviluppatore'],
    '/fatturatoperclientifotovoltaico': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale', 'Agente', 'Banco Bari', 'Banco Lecce', 'Banco Oria', 'Banco Casarano', 'Amministrazione'],
    '/fatturatomensilefotovoltaico': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale', 'Agente', 'Banco Bari', 'Banco Lecce', 'Banco Oria', 'Banco Casarano', 'Amministrazione'],
    '/confrontoagentebrand': ['Sviluppatore', 'Direzione', 'Direzione Commerciale', 'Amministrazione'],
    '/confrontoagentefotovoltaico': ['Sviluppatore', 'Direzione', 'Direzione Commerciale', 'Amministrazione'],
    '/ordiniinevasiclienti': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale', 'Agente', 'Banco Bari', 'Banco Lecce', 'Banco Oria', 'Banco Casarano', 'Amministrazione'],
    '/ordiniinevasifiliali': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale', 'Amministrazione'],
    '/ordininonevasifiliali': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale', 'Amministrazione'],
    '/statistichedocumentiarca': ['Sviluppatore', 'Ufficio Acquisti', 'Direzione', 'Direzione Commerciale'],
    '/statorobottino': ['Sviluppatore', 'Amministrazione', 'Direzione', 'Magazzino Martina'],
    '/entrate': ['Sviluppatore'],
    '/uscite': ['Sviluppatore'],
    '/budgetcategorie': ['Sviluppatore'],
    '/costi': ['Sviluppatore'],
    '/listiniribassati': ['Sviluppatore', 'Direzione', 'Ufficio Acquisti', 'Direzione Commerciale'],
    '/importpromo': ['Sviluppatore', 'Direzione', 'Ufficio Acquisti', 'Direzione Commerciale'],
    '/indexincassi': ['Sviluppatore', 'Amministrazione', 'Direzione', 'Agente', 'Banco Bari', 'Banco Lecce', 'Banco Oria', 'Banco Casarano', 'Direzione Commerciale'],
    '/incassibanconista': ['Sviluppatore', 'Amministrazione', 'Direzione', 'Banco Bari', 'Banco Lecce', 'Banco Oria', 'Banco Casarano', 'Direzione Commerciale'],
    '/portafoglioagenti': ['Sviluppatore', 'Amministrazione', 'Direzione', 'Agente', 'Banco Bari', 'Banco Lecce', 'Banco Oria', 'Banco Casarano', 'Direzione Commerciale'],
    '/portafoglioagentibanco': ['Sviluppatore', 'Amministrazione', 'Banco Bari', 'Banco Lecce', 'Banco Oria', 'Banco Casarano'],
    '/portafoglioaccontiagenti': ['Sviluppatore', 'Amministrazione', 'Direzione', 'Agente', 'Banco Bari', 'Banco Lecce', 'Banco Oria', 'Banco Casarano', 'Direzione Commerciale'],
    '/manutenzioneincassi': ['Sviluppatore', 'Amministrazione'],
    '/manutenzioneacconti': ['Sviluppatore', 'Amministrazione'],
    '/documentaleriparazioni': ['Sviluppatore'],
    '/riparazioni': ['Sviluppatore', 'Ufficio Acquisti', 'Magazzino Martina', 'Agente', 'Direzione', 'Direzione Commerciale'],
    '/situazionefornitori': ['Sviluppatore', 'Ufficio Acquisti', 'Magazzino Martina', 'Direzione', 'Direzione Commerciale'],
    '/statisticheriparazioni': ['Sviluppatore', 'Ufficio Acquisti', 'Magazzino Martina', 'Direzione', 'Direzione Commerciale'],
    '/flowriparazioni': ['Sviluppatore'],
    '/sospesiperagente': ['Sviluppatore', 'Amministrazione', 'Direzione'],
    '/saldibanche': ['Sviluppatore', 'Amministrazione', 'Direzione'],
    '/finanziamenti': ['Sviluppatore', 'Amministrazione', 'Direzione'],
    '/manutenzionefinanziamenti': ['Sviluppatore', 'Amministrazione', 'Direzione'],
    '/manutenzionebanche': ['Sviluppatore', 'Amministrazione', 'Direzione'],
    '/presaincaricoriparazione': ['Sviluppatore', 'Amministrazione'],
    '/tabledemo': ['Sviluppatore'],



}

export default routePermissionsMap